import React from 'react';
import Header from '../pages/Header';
import Footer from '../pages/Footer';
import HomeImage2 from '../images/home-image2.png';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';


function Home() {
  return (
    <div>
    <Header />
    <div className='home-container'>
        <section className="home-col">
        <section className="primary">
          <h1>Working with founders and businesses
             to <span> succeed</span> and discover unimaginable 
             possibilities 
          </h1>
          <p>Greatman Legend (formerly The GIG-Group) is an innovation-driven management and 
            investment company focused on leading early-stage or 
            underperforming businesses to become scalable and successful 
            enterprises by providing access to funding and infrastructure but,
            most importantly, mentorship.
          </p>
        </section>
        <section className='primary2'>
          <img src={HomeImage2} alt="business-lady" />
        </section>
        </section>
        <section className="secondary">
          <h2>Having built businesses from <span>zero to one,</span> we have the blueprints for growth. 
            We are committed to replicating the same with companies
            and teams guided by our resilience, agility, long-term
             orientation, and innovation principles.
          </h2>
          <section className="secondary2">
          <p>Running a business can be challenging without 
            the right experience, network and guidance. 
            GML makes a difference through an integrated 
            approach of capital, strategy and operational 
            support. We believe that by supporting entrepreneurs
             through the different stages of building a business, we create positive social-economic impacts and propel human progress. 

          </p>
          <Link to="investmentmandate">See investement mandate</Link>
          </section>
        </section>
        <section className='home-image'>
           <div className="home-image-col">
           <p>Our portfolio is diversified, cutting across technology-focused 
              projects, finance, logistics, mobility and media.</p>
              <AiOutlineArrowRight id="home-arrow"/>
           </div>
        </section>
        {/* <section className="tertiary">
          <h2>We are a dedicated investment team who work hard and smart to
            create enabling environments for great business <span> ideas</span>  to thrive
            and contribute to the advancement of human progress.
          </h2>
          <div className="tertiary2">
          <p>With GML, you never walk alone. We hold your hands every step of
            the way, and together, we can build the next great business.
          </p>
          <button>Meet the team</button>
          <img src={HomeImage} alt="Smiling girl" />
          </div>
        </section> */} 
    </div>
    <Footer />
    </div>
  )
}


export default Home;
