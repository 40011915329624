import React from 'react';
import Header from '../pages/Header';
import Footer from '../pages/Footer';
import { BsArrowUpRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';

function Contact() {
  return (
    <div>
    <Header />
    <div className='contact-container'>
      <section class="contact-section">
        <div class="contact-section-col">
          <h1>Need to reach us ? We are an email away.</h1>
          <p>Send an email to <span>info@greatmanlegend.com</span>,
           or complete the contact form and we will get back.</p>
           <div className="btn-se">
             <Link className="inner " to="/team">
               <div id="circle"></div>
                 <h5>Call us</h5>
                <BsArrowUpRight style={{ color: 'black', width: '2vw', height: '2vw'}}/>
            </Link>
            <Link className="inner inner2" to='/portfolio'>
               <div id="circle"></div>
                <h5>Message us</h5>
                <BsArrowUpRight style={{ color: 'black', width: '2vw', height: '2vw'}}/>
            </Link> 
           </div>        
        </div>
        <form action="#" class="contact-section-col">
          <div>
            <label for="fullname">Full Name</label>
            <input type="text" name="fullname" id="fullname" placeholder="Enter your full name" required />
          </div>
          <div>
            <label for="emailaddress">Email Address</label>
            <input type="email" name="emailaddress" id="emailaddress" placeholder="Enter your email address" required />
          </div>
          <div>
            <label for="msg">Message</label>
            <textarea type="text" name="msg" id="msg" placeholder="Your message here" required></textarea>
          </div>
          <button type="submit">Submit</button>
        </form>
      </section>
    </div>
    <Footer />
    </div>
  )
}

export default Contact;
