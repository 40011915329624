import React from 'react';
import Header from '../pages/Header';
import Footer from '../pages/Footer';
import TeamImage from '../images/team-image.png';
import TeamImage2 from '../images/team-GML.png';

function Team() {
  return (
    <div>
    <Header />
    <div className='team-container'>
      <section className="primary">
        <h2>
        We are creators, technologists and business leaders driven by
        <span> growth</span> and the passion for solving local and 
        global challenges.
        </h2>
        <p>
        GML is backed by an exceptional management team with years of
        experience in technology, finance, resource management, business/operational
        excellence and investments.
        </p>
      </section>
      <section className="secondary">
        <img class="team-mobile" src={TeamImage} alt="team image" />
        <img class="team-deskstop" src={TeamImage2} alt="team image" />
      </section>
    </div>
    <Footer />
    </div>
  )
}

export default Team;
