import React from 'react';
import Header from '../pages/Header';
import Footer from '../pages/Footer';
import { BsPeopleFill } from 'react-icons/bs';
import { BsFillStarFill } from 'react-icons/bs';
import InvestmentImage from '../images/investment-image.png';
import { BsArrowUpRight } from 'react-icons/bs';
import { GiLoveHowl } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import InvestmentImage2 from '../images/invest-image.png';


function Investment() {
  return (
    <div>
    <Header />
    <div className='investment-container'>
      <section className="invest-col">
      <section className="primary">
        <h2>We are intentional about the ideas and businesses we 
          invest in for these simple reasons.
        </h2>
        <div className="btn-se">
          <Link className="inner " to="/team">
            <div id="circle"></div>
              <h5>People </h5>
              <BsArrowUpRight style={{ color: 'black', width: '2vw', height: '2vw'}}/>
          </Link>
          <Link className="inner inner2" to='/portfolio'>
            <div id="circle"></div>
              <h5>Progress</h5>
              <BsArrowUpRight style={{ color: 'black', width: '2vw', height: '2vw'}}/>
          </Link> 
          <Link className="inner inner2" to='/portfolio'>
            <div id="circle"></div>
              <h5>Passion</h5>
              <BsArrowUpRight style={{ color: 'black', width: '2vw', height: '2vw'}}/>
          </Link> 
        </div> 
      </section>
      <section className="invest-image">
        <img src={InvestmentImage2} alt="business woman" />
      </section>
      </section>
      <section className="investment-col">
      <section className="secondary ro">
        <div className="col">
          <BsPeopleFill id="inve-style" />
          <div className="col-invest">
          <h3>People</h3>
          <p> Intelligent people yield unlimited possibilities.
            Every project or idea is only as great as the people
            behind it.</p>
          </div>
        </div>
        <div className="col">
        <GiLoveHowl id="inve-style" />
          <div className="col-invest">
          <h3>Passion</h3>
          <p>Passion fuels action. Our investment approach is “all or nothing.” 
            We get our hands dirty doing the work required for growth,
            change and innovation.</p>
          </div>
        </div>
        <div className="col">
        <BsFillStarFill id="inve-style" />
          <div className="col-invest">
          <h3>Progress</h3>
          <p>If the end goal positively impacts and leads to human
             progress, we get right into it. We identify and invest
              in passionate people with a shared vision looking to 
              solve real problems that promote human progress through 
              authentic business initiatives or ideas.
          </p>
          </div>
        </div>
      </section>
      <section className="tertiary ro">
        <img src={InvestmentImage} alt="sky scrapper" />
      </section>
      </section>
    </div>
    <Footer />
    </div>
  )
}

export default Investment;
