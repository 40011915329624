import React from 'react';
import logo from '../images/GML.svg';
import logoBlack from '../images/GML-black.svg';
import logoWhite from '../images/GML-white.svg';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer>
      <section className="primary">
        <img src={logo} className="app-logo-footer" alt="greatmenlegend-logo" />
        <img src={logoBlack} className="app-logo-footer-black" alt="greatmenlegend-logo" />
        <img src={logoWhite} className="app-logo-footer-white" alt="greatmenlegend-logo" />
        <h2>GREATMAN <span>LEGEND</span></h2>
        <h5>All rights reserved. Copyright 2022</h5>
      </section>  
      <nav className="secondary">
        <li><Link to="/">HOME</Link></li>
        <li><Link to="/about"  >ABOUT US</Link></li>
        <li><Link to="/investmentmandate">INVESTMENT MANDATE </Link></li>
        <li><Link to="/team"  >TEAM</Link></li>
        <li><Link to="/portfolio"  >PORTFOLIO</Link></li>
        <li><Link to="/contact"  >CONTACT</Link></li>
      </nav>
    </footer>
  )
}

export default Footer
