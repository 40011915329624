import './App.css';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import About from './pages/About';
import Investment from './pages/Investment';
import Portfolio from './pages/Portfolio';
import Team from './pages/Team';


function App() {
  return (
    <div className="body-container">
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="investmentmandate" element={<Investment />} />
        <Route exact path="team" element={<Team />} />
        <Route exact path="about" element={<About />} />
        <Route exact path="portfolio" element={<Portfolio />} />
        <Route exact path="contact" element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;
