import React from 'react';
import Header from '../pages/Header';
import Footer from '../pages/Footer';
import aboutImage from '../images/about-GML.png';

function About() {
  return (
    <div>
    <Header />
    <div className='about-container'>
        <section className="primary">
          <h1>Every idea has the potential to become groundbreaking 
            innovation. 
          </h1>
          <p>Over the years, we have built and supported 
            businesses that have become leaders in their respective 
            domains. We are passionate about connecting businesses 
            with the resources needed for success, human or material,
             because we believe that collaboration births innovation.
             <br></br>
             <br></br>
            Our management and leadership style is reminiscent of the 
             Japanese principle “Genchi Genbutsu.” We are not boardroom
              investors who make decisions only on the table; we go all
              out into the field. The goal is to understand problems from 
              the roots and provide solutions that truly work.
              <br></br>
              <br></br>
            We work hard but also smart to create enabling environments 
              for great business ideas to thrive and contribute to the 
              advancement of human progress. With GML, you never walk 
              alone. We hold your hands every step of the way because together,
               we can build the next great business.
          </p>
        </section>
        <section className="secondary">
            <img src={aboutImage} alt="Man smiling" />
        </section>
    </div>
    <Footer />
    </div>
  )
}

export default About;
