import React from 'react';
import Header from '../pages/Header';
import Footer from '../pages/Footer';
import JetMotor from '../images/JET-logomark.svg';
import Richmondhill from '../images/RichmondHill-Logomark.svg'
import GIGL from '../images/gigl.svg';
import GIGM from '../images/gigm.svg';
import Stellas from '../images/Stellas-logo.svg';
import Ziuss from '../images/ziuss-logomark.svg';

function Portfolio() {
  return (
    <div className='page-black'>
    <Header className="page-black-header"/>
    <div className='portfolio-container'>
      <section className="primary">
      <h2>Our investment interest is <span> diversified.</span> 
         <br />Our portfolio spans several sectors of <br/>
the economy, each one no less <br/>
important than the other
        </h2>
      </section>
      <section className="secondary">
        <div className="col-con">
        <div className="col">
         <a href='https://giglogistics.com/' target='_blank' style={{ textDecoration: 'none', color: 'white' }}><img src={GIGL} alt="gig logistics" /> </a> 
        </div>
        <a href='https://giglogistics.com/' target='_blank' style={{ textDecoration: 'none', color: 'white' }}>
        <div className="col-text">
          <h5>GIGL</h5>
          <h6>LOGISTICS</h6>
        </div>
        </a>
        </div>
        <div className="col-con">
        <div className="col">
        <a href='https://gigm.com/' target='_blank' style={{ textDecoration: 'none', color: 'white' }}> <img src={GIGM} alt="gig Motors" /> </a>
        </div>
        <a href='https://giglogistics.com/' target='_blank' style={{ textDecoration: 'none', color: 'white' }}>
        <div className="col-text">
          <h5>GIGM</h5>
          <h6>MOBILITY</h6>
        </div>
        </a>
        </div>
        <div className="col-con">
        <div className="col">
       <a href='https://stellasbank.com/' target='_blank' style={{ textDecoration: 'none', color: 'white' }}>    <img src={Stellas} alt="Stellas Microfinance" /></a>
        </div>
        <a href='https://stellasbank.com/' target='_blank' style={{ textDecoration: 'none', color: 'white' }}> 
        <div className="col-text">
          <h5>STELLAS</h5>
          <h6>DIGITAL BANKING</h6>
        </div>
        </a>
        </div>  
        <div className="col-con">
        <div className="col">
         <a href='https://www.ripplesnigeria.com/' target='_blank' style={{ textDecoration: 'none', color: 'white' }}> <img src={Richmondhill} alt="Richmondhill" /></a></div>
         <a href='https://www.ripplesnigeria.com/' target='_blank' style={{ textDecoration: 'none', color: 'white' }}> 
        <div className="col-text">
          <h5>RICHMONDHILL</h5>
          <h6>MEDIA</h6>
        </div>
        </a>
        </div>
        <div className="col-con">
        <div className="col">
        <a href='https://www.jetmotorcompany.com/' target='_blank' style={{ textDecoration: 'none', color: 'white' }}>   <img src={JetMotor} alt="Jetmotors" /> </a>
        </div>
        <a href='https://www.jetmotorcompany.com/' target='_blank' style={{ textDecoration: 'none', color: 'white' }}>
        <div className="col-text">
          <h5>JET MOTORS</h5>
          <h6>EV/AUTOMOBILE ASSEMBLY</h6>
        </div>
        </a>
        </div>
      
        <div className="col-con">
        <div className="col">
        <a href='https://www.ziusspowerng.com/' target='_blank' style={{ textDecoration: 'none', color: 'white' }}>  <img src={Ziuss} alt="ziuss" /></a>  
        </div>
        <a href='https://www.ziusspowerng.com/' target='_blank' style={{ textDecoration: 'none', color: 'white' }}> 
        <div className="col-text">
          <h5>ZIUSS</h5>
          <h6>GREEN ENERGY/SOLAR</h6>
        </div>
        </a>
        </div>
      </section>
    </div>
    <Footer className="page-black" />
    </div>
  )
}

export default Portfolio;
