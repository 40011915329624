import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import { FiMenu } from 'react-icons/fi';
import { BsArrowRight } from 'react-icons/bs';
import logo from '../images/GML-black.svg';
import logo2 from '../images/GML-white.svg';
import './Mobile.css';
import './Deskstop.css';

function Header() {
    const [navbarOpen, setNavbarOpen] = useState(false);

    const handleToggle = () => {
      setNavbarOpen(!navbarOpen);
    };
  
    const closeMenu = () => {
      setNavbarOpen(false);
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    };

  return (
    <header>
      <div className={`logo-header ${navbarOpen ? 'navmenu-header' : ''}`}>
          {navbarOpen ? `` : <Link to="/" onClick={() => closeMenu()}>
          <img src={logo} className="app-logo" alt="greatmenlegend-logo" />
          <img src={logo2} className="app-logo2" alt="greatmenlegend-logo" />
          {' '}
        </Link>}
        <button className={`menu-btn ${navbarOpen ? 'navmenu-btn' : ''}`} type="button" id="menu" onClick={handleToggle}>
          {navbarOpen ? (
            <MdClose style={{ color: 'black', width: '25px', height: '25px' ,backgroundColor: "white"}} />
          ) : (
            <FiMenu style={{ color: 'white', width: '25px', height: '25px',backgroundColor: "black" }} />
          )}
        </button>
      </div>
      <nav className='navBar'>
        <ul className={`menuNav ${navbarOpen ? 'showMenu' : ''}`}>
          <li><NavLink to="/" onClick={() => closeMenu()} >Home<BsArrowRight style={{width: '20px'}}/></NavLink></li>
          <li><NavLink activeClassName="active" to="/about" onClick={() => closeMenu()} >About Us<BsArrowRight style={{width: '20px'}}/></NavLink></li>
          <li><NavLink activeClassName="active" to="/investmentmandate" onClick={() => closeMenu()} >Investment Mandate <BsArrowRight style={{width: '20px'}}/></NavLink></li>
          <li><NavLink activeClassName="active" to="/team" onClick={() => closeMenu()} >The Team<BsArrowRight style={{width: '20px'}} /></NavLink></li>
          <li><NavLink activeClassName="active" to="/portfolio" onClick={() => closeMenu()} >Portfolio<BsArrowRight style={{width: '20px'}} /></NavLink></li>
          <li><NavLink activeClassName="active" to="/contact" onClick={() => closeMenu()} >Contact<BsArrowRight style={{width: '20px'}} /></NavLink></li>
        </ul>
      </nav>
    </header>
  )
}

export default Header;
